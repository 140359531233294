import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const LINKS = [
  '/clutch-global-leader/?utm_source=clutch.co&utm_medium=referral&utm_campaign=developers',
  '/clutch-global-leader/?utm_source=clutch.co&utm_medium=referral&utm_campaign=featured-listing-software-developers-eastern-europe',
];

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Button = styled.button`
  width: 50px;
  margin-right: 25px;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
`;

export const ClutchButtons = () => {
  return (
    <Wrapper>
      {LINKS.map((link, index) => {
        return (
          <Button key={index} onClick={() => navigate(link)}>
            {index}
          </Button>
        );
      })}
    </Wrapper>
  );
};
